import React, { useEffect, useRef, useState } from 'react';
import '../assets/allnft/css/style.css';
import Layout from '../component/Layout';
import { useParams } from 'react-router-dom';
import { BACKEND_URL, URI_PREFIX, api } from '../hooks/constant';
import Loader from '../component/Loader';
import NftModal from '../component/NftModal';
import { useMintStats } from '../stats/useStats';
import { formatPrice } from '../hooks/contractHelper';
import bnbImg from '../assets/images/icon.png';
import iconImg from '../assets/images/icon.png';
import bannerImg from '../assets/images/banner-collection.jpg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import audioImg from '../assets/images/music-banner.jpg';
import playImg from '../assets/images/play.png';
import pausedImg from '../assets/images/pause.png';

export default function SubCategory() {
    const [categoryData, setCategoryData] = useState([]);
    const [subData, setSubData] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [modalShow, setModalShow] = React.useState(false);
    const [updater, setUpdater] = useState(1)
    const minStats = useMintStats(updater)
    const [selectedNft, setSelectedNft] = useState({});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [playingAudio, setPlayingAudio] = useState(null);
    const audioRefs = useRef([]);

    useEffect(() => {
        async function fetch() {

            const response1 = await api.post('get_sub_category', {
                id: id
            })

            if (response1 && response1.data && response1.data) {
                console.log(response1.data)
                setCategoryData(response1.data)
                setLoading(false)
            }

            const response = await api.post(`get_nft?page=${page}`, {
                "per_page": 4,
                "sub_category_id": id,
            })

            if (response && response.data) {
                if (page === 1) {
                    setSubData(response.data.data);
                    setLastPage(response.data.last_page);
                }
                else {
                    setSubData(prevData => [...prevData, ...response.data.data]);
                    setLastPage(response.data.last_page);
                }
            }
        }
        if (id) {
            fetch()
        }
    }, [id, page]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const playAudio = (index) => {
        if (playingAudio !== null) {
            audioRefs.current[playingAudio].pause();
        }
        audioRefs.current[index].play();
        setPlayingAudio(index);
    };

    const stopAudio = (index) => {
        audioRefs.current[index].pause();
        audioRefs.current[index].currentTime = 0;
        setPlayingAudio(null);
    };

    return (
        <Layout>

            <section class="tf-banner-collection">
                {!loading && categoryData && categoryData.title ? (
                    <div class="tf-container">
                        <div class="row">
                            <div class="col-md-12">
                                <Link to={`/category/${categoryData.category_id}`} class="btn btn-link">Back</Link>
                                <div class="banner-collection-inner">
                                    <img src={categoryData.banner ? `${BACKEND_URL}${categoryData.banner}` : bannerImg} alt="banner" class="img-bg" />
                                    <img src={`${BACKEND_URL}${categoryData.image}`} alt="banner" class="img-banner" />
                                </div>

                                <div class="tf-heading style-5">
                                    <h2 class="heading">{categoryData.title}</h2>
                                    <h5>Volume : <img src={iconImg} alt='icon' className='mx-2' style={{ height: "24px", width: "24px", display: "inline-flex" }} />{categoryData.volume}</h5>
                                </div>

                            </div>
                            {subData && subData.length > 0 ? (
                                subData.map((items, key) =>
                                (

                                    <div key={key} class="col-lg-3 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                                        <div class="sc-product style2">
                                            <div class="features">
                                                <div class="product-media">
                                                    {items.file_type === "2" ? (
                                                        <video controls>
                                                            <source src={`${URI_PREFIX}${items.image}`} type="video/mp4" />
                                                        </video>
                                                    ) : items.file_type === "3" ? (
                                                        <>
                                                            <img src={audioImg} className='nft-img' alt="audio" />
                                                            <audio ref={el => audioRefs.current[key] = el} src={`${URI_PREFIX}${items.image}`} type="audio/mpeg" />
                                                            <div style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 10
                                                            }}>
                                                                {playingAudio === key ? (


                                                                    <div class="wrapper" onClick={() => stopAudio(key)}>
                                                                        <div class="circle pulse"></div>
                                                                        <div class="circle">
                                                                            <img src={pausedImg} alt='play' height="50px" width="50px" style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',

                                                                            }} />
                                                                        </div>
                                                                    </div>

                                                                ) : (
                                                                    <div class="wrapper" onClick={() => playAudio(key)}>
                                                                        <div class="circle pulse"></div>
                                                                        <div class="circle" >
                                                                            <img src={playImg} alt='play' height="24px" width="24px" style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',

                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <img src={`${URI_PREFIX}${items.image}`} className='nft-img' alt="images" />
                                                    )}
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center mb-3'>
                                                <img className='collection-img mx-1' src={`${BACKEND_URL}${items.get_sub_category?.image}`} alt='banner' />
                                                <span className='text-sm'>{items.get_sub_category?.title}</span>
                                            </div>
                                            <a href="#sec" class="tag">{items.name}</a>
                                            <div class="bottom-style2 mt-2">

                                                <div class="price">
                                                    <div class="icon">
                                                        <img src={bnbImg} alt="images" />
                                                    </div>
                                                    <div class="content">
                                                        <div class="name">Price</div>
                                                        <div class="cash">{minStats.price ? formatPrice(minStats.price) : 0} HENG</div>
                                                    </div>
                                                </div>
                                                <div class="product-button">
                                                    {items.is_purchased && items.is_purchased === 1 ? (
                                                        <button type='button' class="tf-button btn-warning">Sold</button>
                                                    ) : (
                                                        <button type='button' onClick={() => {
                                                            setModalShow(true)
                                                            setSelectedNft(items)
                                                        }} class="tf-button">Purchase</button>
                                                    )}

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                )
                                )
                            ) : (
                                <section class="cab-row">
                                    <div class="cab-row-card">
                                        <div class="cab-card-wrap your-stake-card">
                                            <div class="stake-nft-container-empty">
                                                <div>
                                                    <div class="empty-icon nostake-icon"></div>
                                                    <div class="empty-title h4">No Record Found</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}

                            {page < lastPage && (
                                <div className="col-md-12">
                                    <div className="btn-loadmore mt8 wow fadeInUp">
                                        <button onClick={loadMore} className="tf-button loadmore style-4">Load more</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <Loader loading={loading} />
                )}
            </section>

            <NftModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                minStats={minStats}
                setUpdater={setUpdater}
                selectedNft={selectedNft}
            />
        </Layout>
    )
}
