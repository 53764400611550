import React, { useEffect, useRef, useState } from 'react';
import '../assets/allnft/css/style.css';
import Layout from '../component/Layout';
import bnbImg from '../assets/images/icon.png';
import { BACKEND_URL, URI_PREFIX, api } from '../hooks/constant';
import { useLocation } from 'react-router-dom';
import NftModal from '../component/NftModal';
import { useMintStats } from '../stats/useStats';
import { formatPrice } from '../hooks/contractHelper';
import audioImg from '../assets/images/music-banner.jpg';
import playImg from '../assets/images/play.png';
import pausedImg from '../assets/images/pause.png';

export default function AllNFTs() {
    const location = useLocation();
    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState({
        id: location.state && location.state.c_id ? location.state.c_id : 0,
        name: location.state && location.state.c_name ? location.state.c_name : ''
    });

    const [nftdata, setNftdata] = useState([]);
    const [sortby, setSortby] = useState({
        id: 'random',
        name: 'Random'
    });
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [modalShow, setModalShow] = React.useState(false);
    const [updater, setUpdater] = useState(1)
    const minStats = useMintStats(updater)
    const [selectedNft, setSelectedNft] = useState({});
    const [playingAudio, setPlayingAudio] = useState(null);
    const audioRefs = useRef([]);

    useEffect(() => {
        async function fetch() {
            const response = await api.post('get_category')

            if (response && response.data && response.data.data) {
                setCategory(response.data.data)
            }
        }

        fetch()
    }, []);



    useEffect(() => {
        async function fetch() {

            const response = await api.post(`get_nft?page=${page}`, {
                "sort_by": sortby.id,
                "per_page": 8,
                "category_id": categoryId.id,
            })

            if (response && response.data) {
                if (page === 1) {
                    setNftdata(response.data.data);
                    setLastPage(response.data.last_page);
                }
                else {
                    setNftdata(prevData => [...prevData, ...response.data.data]);
                    setLastPage(response.data.last_page);
                }
            }
        }

        fetch()
    }, [categoryId, page, sortby]);


    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleCategoryChange = ({ id, name }) => {
        setCategoryId({
            id,
            name
        });
        setNftdata([]);
        setPage(1); // Reset the page number to 1 to load fresh data for the new category
    };

    const handleChangeOrderBy = ({ id, name }) => {
        setSortby({
            id,
            name
        });
        setNftdata([])
        setPage(1); // Reset the page number to 1 to load fresh data for the new category
    }


    const playAudio = (index) => {
        if (playingAudio !== null) {
            audioRefs.current[playingAudio].pause();
        }
        audioRefs.current[index].play();
        setPlayingAudio(index);
    };

    const stopAudio = (index) => {
        audioRefs.current[index].pause();
        audioRefs.current[index].currentTime = 0;
        setPlayingAudio(null);
    };


    return (
        <Layout>

            <section class="tf-section tf-explore tf-filter">
                <div class="tf-container">
                    <div class="row ">
                        <div class="col-md-12 mt-5">
                            <div class="tf-heading style-2 wow fadeInUp">
                                <h4 class="heading">Explore</h4>
                            </div>
                        </div>
                        <div className='col-2 mb-5'>
                            <div id="item_category" class="dropdown">
                                <a href="#sec" class="btn-selector nolink">{categoryId.name ? categoryId.name : 'Category'}</a>
                                <ul>
                                    <li onClick={() => handleCategoryChange({
                                        id: 0,
                                        name: ''
                                    })} class={`${categoryId.id === 0 ? 'active' : ''}`}>
                                        <span>All</span>
                                    </li>
                                    {category && category.length > 0 && category.map((items, index) => {
                                        return (
                                            <li onClick={() => handleCategoryChange({
                                                id: items.id,
                                                name: items?.title
                                            })} class={`${items.id === categoryId.id ? 'active' : ''}`}><span>{items?.title}</span></li>
                                        )
                                    })}

                                </ul>
                            </div>
                        </div>
                        <div className='col-2 mb-5'>
                            <div id="item_sort" class="dropdown">
                                <a href="#sec" class="btn-selector nolink">{sortby.name ? sortby.name : 'Sort By'}</a>
                                <ul>
                                    <li onClick={() => handleChangeOrderBy({
                                        id: 'random',
                                        name: 'Random'
                                    })}><span>Random</span></li>
                                    <li onClick={() => handleChangeOrderBy({
                                        id: 'asc',
                                        name: 'Ascending'
                                    })}><span>Ascending</span></li>
                                    <li onClick={() => handleChangeOrderBy({
                                        id: 'desc',
                                        name: 'Descending'
                                    })}><span>Descending</span></li>

                                </ul>
                            </div>
                        </div>
                        {/* <div class="col-md-12">
                            <ul class="filter-menu style-2 wow fadeInUp">
                                {category && category.length > 0 && category.map((items, index) => {
                                    return (
                                        index <= 10 && <li class={`${items.id === categoryId ? 'active' : ''}`} onClick={() => setCategoryId(items.id)}><a href="#sec">{items?.title}</a></li>
                                    )
                                })}
                            </ul>
                        </div> */}
                    </div>
                    <div class="row tf-filter-container wow fadeInUp">
                        {nftdata && nftdata.length > 0 && nftdata.map((items, key) => {
                            return (
                                <div key={key} class="col-lg-3 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                                    <div class="sc-product style2">
                                        <div class="features">
                                            <div class="product-media">
                                                {items.file_type === "2" ? (
                                                    <video controls>
                                                        <source src={`${URI_PREFIX}${items.image}`} type="video/mp4" />
                                                    </video>
                                                ) : items.file_type === "3" ? (
                                                    <>
                                                        <img src={audioImg} className='nft-img' alt="audio" />
                                                        <audio ref={el => audioRefs.current[key] = el} src={`${URI_PREFIX}${items.image}`} type="audio/mpeg" />
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 10
                                                        }}>
                                                            {playingAudio === key ? (


                                                                <div class="wrapper" onClick={() => stopAudio(key)}>
                                                                    <div class="circle pulse"></div>
                                                                    <div class="circle">
                                                                        <img src={pausedImg} alt='play' height="50px" width="50px" style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',

                                                                        }} />
                                                                    </div>
                                                                </div>

                                                            ) : (
                                                                <div class="wrapper" onClick={() => playAudio(key)}>
                                                                    <div class="circle pulse"></div>
                                                                    <div class="circle" >
                                                                        <img src={playImg} alt='play' height="24px" width="24px" style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',

                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <img src={`${URI_PREFIX}${items.image}`} className='nft-img' alt="images" />
                                                )}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center mb-3'>
                                            <img className='collection-img mx-1' src={`${BACKEND_URL}${items.get_sub_category?.image}`} alt='banner' />
                                            <span className='text-sm'>{items.get_sub_category?.title}</span>
                                        </div>
                                        <a href="#sec" class="tag">{items.name}</a>
                                        <div class="bottom-style2 mt-2">

                                            <div class="price">
                                                <div class="icon">
                                                    <img src={bnbImg} alt="images" />
                                                </div>
                                                <div class="content">
                                                    <div class="name">Price</div>
                                                    <div class="cash">{minStats.price ? formatPrice(minStats.price) : 0} HENG</div>
                                                </div>
                                            </div>
                                            <div class="product-button">
                                                {items.is_purchased && items.is_purchased === 1 ? (
                                                    <button type='button' class="tf-button btn-warning">Sold</button>
                                                ) : (
                                                    <button type='button' onClick={() => {
                                                        setModalShow(true)
                                                        setSelectedNft(items)
                                                    }} class="tf-button">Purchase</button>
                                                )}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}


                    </div>
                    {page < lastPage && (
                        <div className="col-md-12">
                            <div className="btn-loadmore mt8 wow fadeInUp">
                                <button onClick={loadMore} className="tf-button loadmore style-4">Load more</button>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <NftModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                minStats={minStats}
                setUpdater={setUpdater}
                selectedNft={selectedNft}
            />
        </Layout>
    )
}
