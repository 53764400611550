import React, { useEffect, useRef, useState } from 'react';
import '../assets/allnft/css/style.css';
import nftImg from '../assets/images/nft.png'
import Layout from '../component/Layout';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import createImg1 from '../assets/images/icon-create-1.svg'
import createImg3 from '../assets/images/icon-create-3.svg'
import createImg4 from '../assets/images/icon-create-4.svg'
import bnbImg from '../assets/images/icon.png';
import logoImg from '../assets/images/logo.png';
import { BACKEND_URL, DEFAULT_CHAIN, EXPLORE_URL, URI_PREFIX, api, contract } from '../hooks/constant';
import { Link, useHistory } from 'react-router-dom';
import NftModal from '../component/NftModal';
import { useMintStats } from '../stats/useStats';
import { formatPrice } from '../hooks/contractHelper';
import badgeImg from '../assets/images/top1.png';
import audioImg from '../assets/images/music-banner.jpg';
import playImg from '../assets/images/play.png';
import pausedImg from '../assets/images/pause.png';

export default function AllNFTs() {
    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState({
        id: 0,
        name: ''
    });
    const [nftdata, setNftdata] = useState([]);
    const history = useHistory();
    const [modalShow, setModalShow] = React.useState(false);
    const [updater, setUpdater] = useState(1)
    const minStats = useMintStats(updater)
    const [selectedNft, setSelectedNft] = useState({});
    const [bannerImg, setBannerImg] = useState('')
    const [playingAudio, setPlayingAudio] = useState(null);
    const audioRefs = useRef([]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    useEffect(() => {
        async function fetch() {
            const response = await api.get('get_banner')

            if (response && response.data && response.data.data) {
                setBannerImg(response.data.data.value)

            }
        }

        fetch()
    }, []);

    useEffect(() => {
        async function fetch() {
            const response = await api.post('get_category')

            if (response && response.data && response.data.data) {
                setCategory(response.data.data)
                response.data.data[0] && response.data.data[0].id && setCategoryId({
                    id: response.data.data[0].id,
                    name: response.data.data[0].title
                })
            }
        }

        fetch()
    }, []);


    const handleClick = (id, name) => {
        history.push('/allnft', { c_id: id, c_name: name });
    };

    const handleCategoryClick = (id) => {
        history.push(`/category/${id}`);
    };


    useEffect(() => {
        async function fetch() {

            const response = await api.post('get_nft', {
                "$schema": "random",
                "per_page": 8,
                "category_id": categoryId
            })

            if (response && response.data) {
                setNftdata(response.data.data)
            }
        }

        fetch()
    }, [categoryId]);


    const playAudio = (index) => {
        if (playingAudio !== null) {
            audioRefs.current[playingAudio].pause();
        }
        audioRefs.current[index].play();
        setPlayingAudio(index);
    };

    const stopAudio = (index) => {
        audioRefs.current[index].pause();
        audioRefs.current[index].currentTime = 0;
        setPlayingAudio(null);
    };

    return (
        <Layout>

            <section class="tf-slider m-5">
                <div class="tf-container">
                    <div class="row d-flex align-items-center">
                        <div className='col-md-6'>
                            <h3 class="heading mb-3">
                                Discover, Collect & Sell HengCoin NFTs
                            </h3>
                            <p class="sub-heading mb-3">Explore HENG NFT collection, Rare art, and Popular NFTs on blockchain more than 100K. Be an owner and earn income from Mint, Stake, Earn.</p>
                            <div class="btn-slider ">
                                <Link to="/allnft" class="tf-button style-2 mx-2">Explore now</Link>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center mt-5 mt-md-0">
                            <div class="image-container">
                                <img src={bannerImg ? `${BACKEND_URL}${bannerImg}` : nftImg} alt="nft" class="nft-all-head-img" />
                                <img src={badgeImg} alt="Badge" class="badge-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section class="tf-section tf-category mt-5">
                <div class="tf-container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tf-heading mb-3 wow fadeInUp">
                                <h4 class="heading">All Categories</h4>
                            </div>
                        </div>
                        <div className='col-12'>
                            <Slider {...settings}>
                                {category && category.length > 0 && category.map((items, index) => {
                                    return (
                                        <div onClick={() => handleCategoryClick(items.id)} className="slider-item" key={index}>
                                            <div className="tf-product-category">
                                                <img src={`${BACKEND_URL}${items?.image}`} alt={items?.name} className="cate-img" />
                                                <div className="category">
                                                    <a href="#sec">{items?.title}</a>
                                                    <a href="#sec">volume : <img src={bnbImg} style={{ height: "14px", width: "14px", display: "inline-flex" }} alt='banner' />{items?.volume}</a>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <section class="tf-section tf-create-and-sell">
                <div class="tf-container">
                    <div class="row">
                        <div class="col-md-12 mb-4">
                            <div class="tf-heading style-2 mb40 wow fadeInUp">
                                <h4 class="heading">Create And Sell NFTs</h4>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="tf-create wow fadeInUp" data-wow-delay="0.2s">
                                <div class="icon">
                                    <img src={createImg1} alt="profile" />
                                </div>
                                <h6 class="title"><a href="#sec"> Set up your wallet</a></h6>
                                <p class="content"> Install and create your wallet. Get your BNB & USDT ready. Go to HENGEx buy HENG token</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="tf-create wow fadeInUp" data-wow-delay="0.6s">
                                <div class="icon">
                                    <img src={createImg3} alt="profile" />
                                </div>
                                <h6 class="title"><a href="#sec">Add Your NFTs</a> </h6>
                                <p class="content">Connect your wallet first. After that select the desired NFT. Click to buy and confirm</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="tf-create wow fadeInUp" data-wow-delay="0.8s">
                                <div class="icon">
                                    <img src={createImg4} alt="profile" />
                                </div>
                                <h6 class="title"><a href="#sec"> Stake & Earn Reward</a></h6>
                                <p class="content">Select the plan you want and press Confirm Stake after that. You rewards are generated</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="tf-section tf-explore tf-filter">
                <div class="tf-container">
                    <div class="row ">
                        <div class="col-md-12">
                            <div class="tf-heading style-2 wow fadeInUp">
                                <h4 class="heading">Explore</h4>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <ul class="filter-menu style-2 wow fadeInUp">
                                {category && category.length > 0 && category.map((items, index) => {
                                    return (
                                        index <= 10 && <li class={`${items.id === categoryId.id ? 'active' : ''}`} onClick={() => setCategoryId({
                                            id: items.id,
                                            name: items.title
                                        })}><a href="#sec">{items?.title}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div class="row tf-filter-container wow fadeInUp">
                        {nftdata && nftdata.length > 0 && nftdata.map((items, key) => {
                            
                            return (
                                <div key={key} class="col-lg-3 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                                    <div class="sc-product style2">
                                        <div class="features">
                                            <div class="product-media">
                                                {items.file_type === "2" ? (
                                                    <video controls>
                                                        <source src={`${URI_PREFIX}${items.image}`} type="video/mp4" />
                                                    </video>
                                                ) : items.file_type === "3" ? (
                                                    <>
                                                        <img src={audioImg} className='nft-img' alt="audio" />
                                                        <audio ref={el => audioRefs.current[key] = el} src={`${URI_PREFIX}${items.image}`} type="audio/mpeg" />
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 10
                                                        }}>
                                                            {playingAudio === key ? (


                                                                <div class="wrapper" onClick={() => stopAudio(key)}>
                                                                    <div class="circle pulse"></div>
                                                                    <div class="circle">
                                                                        <img src={pausedImg} alt='play' height="50px" width="50px" style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',

                                                                        }} />
                                                                    </div>
                                                                </div>

                                                            ) : (
                                                                <div class="wrapper" onClick={() => playAudio(key)}>
                                                                    <div class="circle pulse"></div>
                                                                    <div class="circle" >
                                                                        <img src={playImg} alt='play' height="24px" width="24px" style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',

                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <img src={`${URI_PREFIX}${items.image}`} className='nft-img' alt="images" />
                                                )}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center mb-3'>
                                            <img className='collection-img mx-1' src={`${BACKEND_URL}${items.get_sub_category?.image}`} alt='banner' />
                                            <span className='text-sm'>{items.get_sub_category?.title}</span>
                                        </div>
                                        <a href="#sec" class="tag">{items.name}</a>
                                        <div class="bottom-style2 mt-2">

                                            <div class="price">
                                                <div class="icon">
                                                    <img src={bnbImg} alt="images" />
                                                </div>
                                                <div class="content">
                                                    <div class="name">Price</div>
                                                    <div class="cash">{minStats.price ? formatPrice(minStats.price) : 0} HENG</div>
                                                </div>
                                            </div>
                                            <div class="product-button">
                                                {items.is_purchased && items.is_purchased === "1" ? (
                                                    <button type='button' class="tf-button btn-warning">Sold</button>
                                                ) : (
                                                    <button type='button' onClick={() => {
                                                        setModalShow(true)
                                                        setSelectedNft(items)
                                                    }} class="tf-button">Purchase</button>
                                                )}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}


                    </div>
                    <div class="col-md-12">
                        <div class="btn-loadmore mt8 wow fadeInUp">
                            <button type='button' onClick={() => handleClick(categoryId.id, categoryId.name)} class="tf-button loadmore style-4">Load more</button>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="footer">
                <div class="tf-container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="widget widget-infor">
                                <div class="logo">
                                    <img id="logo_footer" src={logoImg} alt="profile" />
                                </div>
                                <p class="content">Explore HENG NFT collection, Rare art, and Popular NFTs on blockchain more than 100K. Be an owner and earn income from Mint, Stake, Earn.</p>
                                <ul class="social-item">
                                    <li><a target='_blank' rel="noreferrer" href="https://x.com/HENG_COIN"><i class="fab fa-twitter"></i></a></li>
                                    <li><a target='_blank' rel="noreferrer" href="https://www.facebook.com/profile.php?id=100079522894633"><i class="fab fa-facebook"></i></a></li>
                                    <li><a target='_blank' rel="noreferrer" href="https://t.me/HengCoin1"><i class="fab fa-telegram-plane"></i></a></li>
                                    <li><a target='_blank' rel="noreferrer" href="https://www.youtube.com/@Heng_Coin"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                                <p class="copy-right text-white">Copyright © {new Date().getFullYear()} HENGNFT. All Rights Reserved.

                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="widget widget-menu">
                                <div class="menu menu-1">
                                    <h6 class="widget-title">Menu</h6>
                                    <ul >
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/my-nft">My NFTs</Link></li>
                                        <li><Link to="/staked">Staked NFTs</Link></li>
                                        <li><a href={`${EXPLORE_URL}/address/${contract[DEFAULT_CHAIN].NFT_ADDRESS}`} target='_blank' rel="noreferrer">Contract</a></li>
                                    </ul>
                                </div>
                                <div class="menu menu-2">
                                    <h6 class="widget-title">Categories</h6>
                                    <ul >
                                        {category && category.length > 0 && category.map((items, index) => {
                                            return (
                                                index <= 4 &&
                                                <li style={{ cursor: "pointer" }} key={index} onClick={() => handleCategoryClick(items.id)}>{items?.title}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div class="menu menu-3">
                                    <h6 class="widget-title">Exchange</h6>
                                    <ul>
                                        <li><a href={`https://hengex.io/swap/`} target='_blank' rel="noreferrer">Swap</a></li>
                                        <li><a href={`https://hengex.io/liquidity/`} target='_blank' rel="noreferrer">Liquidity</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>

            <NftModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                minStats={minStats}
                setUpdater={setUpdater}
                selectedNft={selectedNft}
            />
        </Layout>
    )
}
