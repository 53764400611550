import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getWeb3 } from '../hooks/connectors';
import { formatPrice, getWeb3NftContract } from '../hooks/contractHelper';
import { contract, DEFAULT_CHAIN, stakePeriod } from '../hooks/constant';
import nftAbi from '../json/nft.json';
import nftstakeAbi from '../json/nftstakeAbi.json';
import { toast } from 'react-toastify';
import { getContract } from '../hooks/contractHelper';
import { useStakeModalStats } from '../stats/useStats';
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from '../hooks/useEthersProvider';



export default function StakeModal({ stakeOpen, setStakeOpen, selectNftData }) {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const [approve, setApprove] = useState(false);
    const [loading, setLoading] = useState(false);
    const [days, setDays] = useState(0);
    let commonStats = useStakeModalStats(selectNftData, days);
    const web3 = getWeb3();

    useEffect(() => {
        async function fetch() {
            try {
                let nftContract = getWeb3NftContract();
                let isApprove = await nftContract.methods.isApprovedForAll(address, contract[DEFAULT_CHAIN].STAKE_ADDRESS).call();
                setApprove(isApprove);
            }
            catch (err) {
                console.log(err.message);
            }
        }


        if (address) {
            fetch();
        }
    }, [address, stakeOpen])

    const handleStake = async () => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                if (selectNftData.length > 0) {
                    try {
                        setLoading(true);
                        let stakeContract = getContract(nftstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS, signer);
                        let tx = await stakeContract.stake(selectNftData, days, { 'from': address });

                        toast.loading('Waiting for confirmation...');
                        var interval = setInterval(async function () {
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('Your last transaction is success!!');
                                    setLoading(false);
                                    setApprove(true);
                                    window.location.reload();
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                }
                            }
                        }, 5000);


                    }
                    catch (err) {
                        toast.error(err.reason ? err.reason : err.message);
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please select NFT first for stake');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please connect to mainnet network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!!');
            setLoading(false);
        }
    }

    const handleApprove = async () => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {
                    setLoading(true);
                    let tokenContract = getContract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS, signer);
                    let tx = await tokenContract.setApprovalForAll(contract[DEFAULT_CHAIN].STAKE_ADDRESS, true, { 'from': address });

                    toast.loading('Waiting for confirmation...');
                    var interval = setInterval(async function () {
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('Your last transaction is success!!');
                                setLoading(false);
                                setApprove(true);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                            }
                        }
                    }, 5000);


                }
                catch (err) {
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please connect to mainnet network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!!');
            setLoading(false);
        }

    }


    return (
        <Modal
            show={stakeOpen}
            onHide={() => setStakeOpen(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Stake NFT</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div class="card-header-icon earn-stake"></div>
                <div className='modal-section'>
                    <div class="h4">Staking Days:</div>
                    <div className='d-flex justify-content-center'>
                        <div class="container1">
                            {stakePeriod && stakePeriod.map((items,key) => (
                                <div class={`numbers1 ${days === key ? 'active' : ''}`} onClick={() => setDays(key)}>
                                    <div>
                                        <span id="days">{items}</span>
                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>

                    <div className='d-flex justify-content-center my-2'>
                        <div class="boost-data-item boost-data-item-main">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="#ea8d3c" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                <line x1="4" y1="9" x2="20" y2="9"></line>
                                <line x1="4" y1="15" x2="20" y2="15"></line>
                                <line x1="10" y1="3" x2="8" y2="21"></line>
                                <line x1="16" y1="3" x2="14" y2="21"></line>
                            </svg>
                            Token Ids :  {selectNftData ? selectNftData.toString() : ' - '}
                            <br />
                        </div>
                    </div>
                    <div className='text-center'>
                        <p>Total Reward : {commonStats.totalSum ? formatPrice(commonStats.totalSum) : 0 }  HENG</p>
                    </div>
                    {approve ? (
                        <button disabled={loading} class="btn btn-select mt-3" type='button' onClick={() => handleStake()}>{loading ? 'loading...' : 'Stake Now'}</button>
                    ) : (
                        <button disabled={loading} class="btn btn-select mt-3" type='button' onClick={() => handleApprove()}>{loading ? 'loading...' : 'Approve'}</button>
                    )}

                </div>

            </Modal.Body>
        </Modal>
    )
}
