import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-rangeslider/lib/index.css'
import './assets/style.css';


import { Web3Modal } from '@web3modal/react';
import { ethereumClient, wagmiConfig } from './hooks/wagmi';
import { PROJECT_ID } from './hooks/constant';
import { WagmiConfig } from 'wagmi'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>

      <App />

    </WagmiConfig>
    <Web3Modal projectId={PROJECT_ID} ethereumClient={ethereumClient} themeVariables={{
      '--w3m-accent-color': '#f1b90a',
      '--w3m-background-color': '#f1b90a',
    }} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
