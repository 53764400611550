import React, { useState } from 'react';
import { getContract } from '../hooks/contractHelper';
import { toast } from 'react-toastify';
import { getWeb3 } from '../hooks/connectors';
import { DECIMALS, DEFAULT_CHAIN, contract } from '../hooks/constant';
import nftAbi from '../json/nft.json';
import nftstakeAbi from '../json/nftstakeAbi.json';
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { ethers } from 'ethers';
import Layout from '../component/Layout';






export default function Admin() {
    const [input, setInput] = useState({
        salestatus: '',
        saleprice: 0,
        withdraw_recevier_address: '',
        withdraw_token_address: '',
        withdraw_token_amount: '',
        withdraw_token_decimals: 18,
        staking_withdraw_token_address : '',
        stake_status : '',
        claim_status : '',
    })
    const rowConfig = [
        {
            id: 1,
            label: 'Sale Status',
            fields: [
                { name: 'salestatus', placeholder: 'enter true or false', type: 'text', value: input.salestatus },
            ],
        },
        {
            id: 2,
            label: `Set Cost (in HENG)`,
            fields: [
                { name: 'saleprice', placeholder: 'enter cost', type: 'text', value: input.saleprice },
            ],
        },
        {
            id: 3,
            label: 'Withdraw Token',
            fields: [
                { name: 'withdraw_recevier_address', value: input.withdraw_recevier_address, placeholder: 'enter receiver address', type: 'text' },
                { name: 'withdraw_token_address', value: input.withdraw_token_address, placeholder: 'enter token address', type: 'text' },
                { name: 'withdraw_token_amount', value: input.withdraw_token_amount, placeholder: 'enter token amount', type: 'text' },
                { name: 'withdraw_token_decimals', value: input.withdraw_token_decimals, placeholder: 'enter token decimals', type: 'number' },
            ],
        },
        {
            id: 4,
            label: 'Withdraw BNB',
            fields: [],
        },
        {
            id: 5,
            label: 'Staking Withdraw BNB',
            fields: [],
        },
        {
            id: 6,
            label: 'Staking Withdraw Token',
            fields: [
                { name: 'staking_withdraw_token_address', value: input.staking_withdraw_token_address, placeholder: 'enter token address', type: 'text' },
            ],
        },
        {
            id: 7,
            label: 'Staking Stake Status',
            fields: [
                { name: 'stake_status', value: input.stake_status, placeholder: 'enter status true or false', type: 'text' },
            ],
        },
        {
            id: 8,
            label: 'Staking Claim Status',
            fields: [
                { name: 'claim_status', value: input.claim_status, placeholder: 'enter status true or false', type: 'text' },
            ],
        },
    ];
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const [loading, setLoading] = useState(false);
    let web3 = getWeb3();



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInput(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    const handleSubmit = async (id) => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {
                    setLoading(true);
                    let nftContract = getContract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS, signer);
                    let stakeContract = getContract(nftstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS, signer);
                    let tx;
                    if (id === 1) {
                        tx = await nftContract.toggleSale(input.salestatus, { 'from': address });
                    }
                    else if (id === 2) {
                        let a = ethers.utils.parseUnits(input.saleprice.toString() , DECIMALS);
                        tx = await nftContract.setPrice(a, { 'from': address });
                    }
                    else if (id === 3) {
                        let a = ethers.utils.parseUnits(input.withdraw_token_amount.toString() , input.withdraw_token_decimals);
                        tx = await nftContract.rescueToken(input.withdraw_token_address,a,input.withdraw_recevier_address, { 'from': address });
                    }
                    else if (id === 4) {
                        tx = await nftContract.rescueBNB({ 'from': address });
                    }
                    else if (id === 5) {
                        tx = await stakeContract.rescueFunds({ 'from': address });
                    }
                    else if (id === 6) {
                        tx = await stakeContract.rescueToken( input.staking_withdraw_token_address ,{ 'from': address });
                    }
                    else if (id === 7) {
                        tx = await stakeContract.setStakeStatus( input.stake_status ,{ 'from': address });
                    }
                    else if (id === 8) {
                        tx = await stakeContract.setClaimStatus( input.claim_status ,{ 'from': address });
                    }
                    else {
                        return false;
                    }



                    toast.loading('Waiting for confirmation...');
                    var interval = setInterval(async function () {
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('Your last transaction is success!!');
                                setLoading(false);

                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                            }
                        }
                    }, 5000);


                }
                catch (err) {
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please connect to mainnet network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!!');
            setLoading(false);
        }

    }

    return (
        <Layout>
            <div class="banner-style-4 rn-section-gapTop">
                <div class="container">
                    <div class="row align-items-center">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Input</th>
                                    <th scope="col">Submit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowConfig.map((item, key) => (
                                    <React.Fragment key={item.id}>

                                        <tr key={key}>
                                            <th scope="row">{key + 1}</th>
                                            <td>{item.label}</td>
                                            <td>
                                                {item.fields.map((field) => (

                                                    <input
                                                        id={field.name}
                                                        name={field.name}
                                                        type={field.type}
                                                        className="form-control mt-1 mb-1"
                                                        placeholder={field.placeholder}
                                                        value={field.value}
                                                        onChange={handleInputChange}
                                                    />

                                                ))}
                                            </td>
                                            <td>
                                                <button
                                                    disabled={loading}
                                                    onClick={()=>handleSubmit(item.id)}
                                                    className="btn btn-radio-submit"
                                                >
                                                    {loading ? 'Loading' : 'Submit'}
                                                </button>
                                            </td>
                                        </tr>

                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>

    )
}
