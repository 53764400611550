import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import logoImg from '../assets/images/logo.png';
import ethLogo from '../assets/images/etherscan-logo.svg';
import { useAdminStats, usePriceFetchStats } from '../stats/useStats';
import { useAccount } from 'wagmi';
import iconImg from '../assets/images/icon.png';
import { formatPrice } from '../hooks/contractHelper';



export default function Sidebar() {
    const router = useLocation();
    const { address } = useAccount();
    const stats = useAdminStats(1);
    const priceStats = usePriceFetchStats()


    return (
        <div className="sidebar">
            <div className="logo-wrap d-flex justify-content-center">
                <Link to="/" >
                    <img src={logoImg} className="logo" height="35px" alt="logo" />
                </Link>
            </div>
            <nav className='mb-5'>
                <ul className="sidebar-nav">
                    <li>
                        <Link to="/" className={`${router.pathname === '/' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-home"></i>
                            </span>
                            <span className="link-name">Home</span>
                        </Link>
                        <Link to="/my-nft" className={`${router.pathname === '/my-nft' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-desktop"></i>
                            </span>
                            <span className="link-name">My NFTs</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/staked" className={`${router.pathname === '/staked' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-box-open"></i>
                            </span>
                            <span className="link-name">Staked NFTs</span>
                        </Link>
                    </li>
                    <li>
                        <a href="#sec">
                            <span className="icon-wrap">
                                <i className="fa-solid fa-warehouse"></i>
                            </span>
                            <span className="link-name">Marketplace</span>
                        </a>
                    </li>
                    {address && stats.owner.toLowerCase() === address.toLowerCase() &&
                        <li>
                            <Link to="/frontend-admin" className={`${router.pathname === '/frontend-admin' ? 'active' : ''}`} >
                                <span className="icon-wrap">
                                    <i class="fa-sharp fa-solid fa-lock"></i>
                                </span>
                                <span className="link-name">Admin</span>
                            </Link>
                        </li>
                    }
                    <li>
                        <a href="https://hengex.io/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-globe"></i>
                            </span>
                            <span className="link-name">Exchange</span>
                        </a>
                    </li>
                    <li>
                        <a href={`https://bscscan.com/token/0x65bba7bdb1ec1c1a0917c9aa766cd4391182e87d`} target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <img src={ethLogo} alt="eth-logo" width={30} height={30} />
                            </span>
                            <span className="link-name">Contract</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0xb6cfc58a364f18f65fa21ea8364260eee0bcbfa3?t=1721961208460" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-globe"></i>
                            </span>
                            <span className="link-name">Chart</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://hengcoin.com/airdrop/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-user-friends"></i>
                            </span>
                            <span className="link-name">Airdrop</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://hengcoin.com/staking/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-money-bill-trend-up"></i>
                            </span>
                            <span className="link-name">Staking</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://docs.hengcoin.com/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-book"></i>
                            </span>
                            <span className="link-name">Document</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://hengcoin.com/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-book"></i>
                            </span>
                            <span className="link-name">HENG Coin</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://hengex.io/swap/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-exchange"></i>
                            </span>
                            <span className="link-name">Buy token</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <div className='d-flex justify-content-center align-items-center p-1' style={{ position: "fixed", bottom: "10px", "left": "15px" }}>
                <a
                    href="https://hengex.io/swap?outputCurrency=0x65bba7bdb1ec1c1a0917c9aa766cd4391182e87d"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center text-decoration-none mx-1"
                >
                    <img src={iconImg} style={{ height: '24px', width: '24px' }} alt='banner' />
                    <span className="mx-1" style={{ fontSize: '14px' }}>
                        ${formatPrice(priceStats.price, 3)}
                    </span>
                </a>
                <a
                    href="https://hengex.io/swap?outputCurrency=0x65bba7bdb1ec1c1a0917c9aa766cd4391182e87d"
                    target="_blank"
                    rel="noreferrer"
                    className='tf-button buy-button d-flex align-items-center'

                >
                    Buy Now
                    <svg
                        className='ml-1'
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ marginLeft: '8px' }}
                    >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                </a>
            </div>
        </div>
    )
}
