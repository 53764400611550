import axios from "axios";
import moment from "moment";


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  56 : {
    MULTICALL_ADDRESS: "0xed386fe855c1eff2f843b910923dd8846e45c5a4",
    NFT_ADDRESS: "0x9b4ceff9001bcf4abbce5d26740cb046cf340461",
    STAKE_ADDRESS: "0xf581f142b0edb945f1776bf052e54c2bc8aa0859",
    TOKEN_ADDRESS : "0x65BBa7bDB1ec1c1A0917C9aa766CD4391182e87d",
    ROUTER_ADDRESS : "0x0e1911b41a10a458327c1a53702c07f1edfd807e",
    PAIR_ADDRESS : "0xb6cfc58a364f18f65fa21ea8364260eee0bcbfa3",
    WETH : "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    USDT_BNB_LP_ADDRESS : "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE"
  },
  97 : {
    MULTICALL_ADDRESS: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18",
    NFT_ADDRESS: "0x104fc89c6a990b7c768de7c0a54255e1c1c1d70c",
    STAKE_ADDRESS: "0x53caccf220377f23e2d37a72fb9020961e6f53c6",
    TOKEN_ADDRESS : "0xceE42Efa4534D69a12E31a65F2Aeb3223840b97A",
    ROUTER_ADDRESS : "0x463df7b0a06f3d06c41ba6549787ccf9e4c93d24",
    PAIR_ADDRESS : "0x5fff7742c714ea69f6439d19a5629876cff0ad3e",
    WETH : "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    USDT_BNB_LP_ADDRESS : "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE"
  }
}

export const EXPLORE_URL="https://bscscan.com"
export const DEFAULT_CHAIN = 56;
// export const RPC = "https://data-seed-prebsc-1-s1.bnbchain.org:8545";
export const RPC = "https://bsc-dataseed1.bnbchain.org";
export const DECIMALS = 18;
export const URI_PREFIX = 'https://alchemy.mypinata.cloud/ipfs/';
export const NFT_SYMBOL = "HENGNFT";
export const PROJECT_ID = '27b9720018bf7f8740936e6a6eb28604';
export const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjFlZjYzOTRiLTk5ZDctNDAxYi1hYTFiLTVjZjk4NDdkNzMzYiIsIm9yZ0lkIjoiNDAxOTU0IiwidXNlcklkIjoiNDEzMDM3IiwidHlwZUlkIjoiNzhhMzI4MmUtZDU1Zi00MWM4LWI2ZjItMWJjNmYwOWI1YmJjIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjIzMTQ1MzAsImV4cCI6NDg3ODA3NDUzMH0.e8S-yeJGkVD-5mMGiAVm70XvCqHjs6kmxKiaz-2Hmb0';
export const MORALIS_BASE_URL = "https://deep-index.moralis.io/api/v2.2/";
export const MORALIS_CHAIN_NAME = 'bsc'
// export const BACKEND_URL = "https://mining.devcodesaga.tech";
export const BACKEND_URL = "https://www.hengnft.com/admin";
// export const BACKEND_URL = "http://localhost/ricesnetwork";




export const formatDate = (unixTime) => {
  try {
    let dateString = moment(unixTime).format('lll');
    return dateString;
  }
  catch (err) {
    console.log(err.message);
  }
}

export const api = axios.create({
  baseURL: `${BACKEND_URL}/api`, // Assuming your API routes are prefixed with /api
  headers: {
    'Content-Type': 'application/json',
  }
});

export const stakePeriod = ["1 Year"];


export const daysLeftFromTimestamp = (timestamp) => {
  // Get the current date and time
  const currentDate = new Date();

  // Parse the given timestamp
  const targetDate = new Date(timestamp);

  // Calculate the difference in time (in milliseconds)
  const timeDifference = targetDate - currentDate;

  // Convert the time difference from milliseconds to days
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const daysLeft = Math.ceil(timeDifference / millisecondsInADay);

  return daysLeft;
}
